import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Card,
    Grid,
    Stack,
    Container,
    Typography,
    Button,
} from '@mui/material';
import { SnackbarProvider } from 'notistack';

// components
import ExcelIcon from '../../../components/excel-icon';
import Iconify from '../../../components/iconify';
import { fDateTime } from '../../../utils/formatTime';

import FormArchivo from './FormArchivo';
import ModalEliminar from './ModalEliminar';

import { detallePromocionesCompraGana, obtenerFechaArchivo } from '../../../services/administrador/compraGana';

// ----------------------------------------------------------------------

export default function AdministradorCompraGana() {
    const [detalles, setDetalles] = useState({});
    const [fecha, setFecha] = useState("");
    const [openForm, setOpenForm] = useState(false);
    const [openModalEliminar, setOpenModalEliminar] = useState(false);

    useEffect(() => {
        // obtenerDetallePromocionesCompraGana();
        getFechaArchivo();
    }, [])

    const obtenerDetallePromocionesCompraGana = async () => {
        const result = await detallePromocionesCompraGana();

        if (result.status) {
            setDetalles(result.data)
        }
    }

    const getFechaArchivo = async () => {
        const result = await obtenerFechaArchivo();
        setFecha(result.data);
    }

    const abrirFormArchivo = () => {
        setOpenForm(true);
    }
    
    const cerrarFormArchivo = () => {
        setOpenForm(false);
    }

    const abrirModalEliminar = () => {
        setOpenModalEliminar(true);
    }
    
    const cerrarModalEliminar = () => {
        setOpenModalEliminar(false);
    }

    return (
        <>
            <Helmet>
                <title> Compra y Gana | Scorpion </title>
            </Helmet>

            {/* Alerta */}
            <SnackbarProvider 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Compra y Gana
                    </Typography>
                </Stack>

                <Container maxWidth="xl">
                    
                    {/* Form Archivo */}
                    <FormArchivo open={openForm} cerrarForm={cerrarFormArchivo} />

                    {/* Modal para eliminar */}
                    <ModalEliminar open={openModalEliminar} cerrarModal={cerrarModalEliminar} />

                    {/* Resumen */}
                    {/* <Grid style={{ display: 'flex', justifyContent: 'space-around', padding: '20px' }}>
                        <Card style={{ padding: '20px', width: '300px' }}>
                            <Typography variant='h5'>
                                Clientes con Puntos
                            </Typography>

                            {Object.keys(detalles).length !== 0 && (
                                <Typography variant='body1'>
                                    De los <strong>{detalles.clientesPuntos.totalClientes}</strong> clientes solo <strong>{detalles.clientesPuntos.totalClientesCompras}</strong> clientes han comprado algún producto.
                                </Typography>
                            )}
                        </Card>

                        <Card style={{ padding: '20px', width: '300px' }}>
                            <Typography variant='h5'>
                                Productos con Puntos
                            </Typography>

                            {Object.keys(detalles).length !== 0 && (
                                <Typography variant='body1'>
                                    De los <strong>{detalles.productosPuntos.productosTotales}</strong> productos solo <strong>{detalles.productosPuntos.productosPuntos}</strong> productos tienen puntos.
                                </Typography>
                            )}
                        </Card>

                        <Card style={{ padding: '20px', width: '300px' }}>
                            <Typography variant='h5'>
                                Pesos Totales Otorgados
                            </Typography>

                            {Object.keys(detalles).length !== 0 && (
                                <Typography variant='body1'>
                                    Suma total de los pesos otorgados: <strong>${detalles.totalPesosOtorgados}</strong>.
                                </Typography>
                            )}
                        </Card>

                        <Card style={{ padding: '20px', width: '300px' }}>
                            <Typography variant='h5'>
                                Promedio de $ por Cliente
                            </Typography>

                            {Object.keys(detalles).length !== 0 && (
                                <Typography variant='body1'>
                                    Promedio de pesos otorgados a los clientes: <strong>${detalles.promedioPesosCliente.toFixed(2)}</strong>.
                                </Typography>
                            )}
                        </Card>
                    </Grid> */}

                    <Card>
                        <Grid style={{ padding: '20px' }} mt={6} mb={6}>
                            <Stack justifyContent="space-between">
                                <Stack alignItems="flex-end" justifyContent="flex-end">
                                    <Button variant="contained" startIcon={<Iconify icon="eva:trash-2-fill" />} color='error' onClick={abrirModalEliminar}>
                                        Eliminar Promociones
                                    </Button>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center" mt={2}>
                                    <Typography variant='h4'>Registra tus promociones de Compra y Gana</Typography>
                                    <Typography variant='body1' mt={7}>Registra las promociones de Compra y Gana para tus usuarios.</Typography>
                                    <Typography variant='body1' mb={7}>Ultimo archivo subido: {fDateTime(fecha)}.</Typography>
                                </Stack>
                                <Stack alignItems="center" justifyContent="center">
                                    <Button variant='contained' onClick={abrirFormArchivo} startIcon={<ExcelIcon />}>
                                        Subir Compra y Gana
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Card>
                </Container>
            </Container>
        </>
    );
}
